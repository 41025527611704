import React from "react";

import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";

// import Home from "./containers/Home";
// import Login from "./containers/Login";
import Signup from "./containers/SignUp";
import Tokens from "./containers/Tokens";
import NotFound from "./containers/NotFound";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Signup} props={childProps} />
    {/* <AppliedRoute path="/login" exact component={Login} props={childProps} /> */}
    <AppliedRoute path="/login" exact component={Signup} props={childProps} />
    <AppliedRoute path="/tokens" exact component={Tokens} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;