import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Tokens.css";
// Rest client
import axios from 'axios';

// Help dealing with query string
import queryString from 'query-string';

export default class Tokens extends Component {

    constructor(props) {

        super(props);
        var qs = queryString.parse(this.props.location.search);
        console.log(qs);
        this.state = {
            redirectUri: 'http://localhost:3000',
            clientId: '709kn55vsc8rmpndjk2inuurnf',
            grantType: 'authorization_code',
            authCode: qs.code
        };

    }

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    GetTokens = () => {

        console.log('Getting the tokens....');

        // If a client_id was suppolied, proceed with retrieiving the client details from ClientInfo
        const httpClient = axios.create();
        httpClient.defaults.timeout = 5000;
        let baseURL = 'https://dev.api.healthshareexchange.org/oauth/token';
        let postBody = 'code=3FtN8Pj167Do0KtWzYnkAF2kEr2qNHIjRngsbrdfQ1o&client_id=709kn55vsc8rmpndjk2inuurnf&redirect_uri=localhost:9999/suphomie';
        httpClient.post(baseURL, postBody).then(res => {
          console.log(res);
        }).catch((error) => {
        // Error making a request to ClientInfo, this should be a timeout from that end point. Display and error.
        console.log('Got an error: ', error);
          if(error.code === 'InvalidLambdaResponseException') {
            this.setState(
              { 
                error: "Email Code was invalid",
                isLoading: false
              }
            );
          } else {
            this.setState(
              { 
                error: error.message,
                isLoading: false
              }
            );
          }
      });  

    }
    render() {
        return (
            <div className="Home">
                <div className="lander">        
                    <form>
                        <div className="panel panel-primary">
                            <div className="panel-heading">Third Party Application That Really Needs a Token</div>
                            <div className="panel-body">
                                <FormGroup controlId="clientId" bsSize="large">
                                    <ControlLabel>Client Id</ControlLabel>
                                    <FormControl
                                        autoFocus
                                        type="text"
                                        value={this.state.clientId}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="grantType" bsSize="large">
                                    <ControlLabel>Grant Type</ControlLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.grantType}
                                        disabled
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="authCode" bsSize="large">
                                    <ControlLabel>Auth Code</ControlLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.authCode}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup controlId="redirectUri" bsSize="large">
                                    <ControlLabel>Redirect Uri</ControlLabel>
                                    <FormControl
                                        type="text"
                                        value={this.state.redirectUri}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>

                                <LoaderButton
                                block
                                bsSize="large"
                                isLoading={this.state.isLoading}
                                text="Get Me Some Tokens"
                                onClick={this.GetTokens}
                                loadingText="Gettin them Tokens!" />

                            </div>
                        </div>
                    </form>
                    {/* <div className="panel-heading">Redirect to:</div>
                    <div className="panel-body">
                    <a href="https://dev.api.healthshareexchange.org/oauth2/token?grant_type=authorization_code&code=QY4IDRFBZOPtYenTOAZK34mSYX%2BCcVOJLj%2BKkTAmMRp4DHYtN6JOp%2FILMjRk1mkl%2Bnllz5yLwixKGs2wwEodUTvEegYMi%2FwKCD9ZiH2MsURO%2FW9yUfuQJW8fDbBrBUTF&redirect_uri=http://localhost:3000">RedirectUri</a>
                    </div>
                    <div className="panel-footer"></div> */}
                </div>
            </div>
        );
    }
}